a {
  text-decoration: none;
}

h1,
h2,
h3,
p {
  text-align: center;
  font-weight: normal;
  line-height: 100%;
  color: #2d2d2d;
}

h1 {
  font-size: 96px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 16px;
}

html {
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding: 0 16px;
}

.fs12 {
  font-size: 12px;
}

.fs16 {
  font-size: 16px;
}

.fs24 {
  font-size: 24px;
}

.mt8 {
  margin-top: 8px;
}

.mt16 {
  margin-top: 16px;
}

.mt24 {
  margin-top: 24px
}

.mt32 {
  margin-top: 32px;
}

.App-header {
  display: flex;
  align-items: center;
}

.App-logo {
  height: 48px;
  margin-right: 16px;
}